import axiosInstance from "../utils/axios";
import {PaginationResponse} from "../types";

export interface PaginationParams {
    pageSize?: number,
    page?: number,
    tableId?: string,
}

export interface Measure {
    description?: string,
    name: string,
    sql: string,
}

export interface DataAssetResponse {
    id: string;
    name: string;
    schema?: string;
    db?: string;
    description: string;
    entities: string[];
    keys: string[];
    businessKeys: string[];
    measures: Measure[]; 
}

export interface DataAssetsPaginationResponse extends PaginationResponse {
    assets: DataAssetResponse[]
}

export interface DataAssetColumn {
    name: string;
    description: string;
    type: string;
}

export interface DataAssetFullResponse extends DataAssetResponse {
    columns: DataAssetColumn[]
}


export const getDataAssets = async (params: PaginationParams | null): Promise<DataAssetsPaginationResponse> => {
    const queryParams = params ? {
        current_page: params.page,
        items_per_page: params.pageSize,
        table_id: params.tableId
    } : {}
    const response = await axiosInstance.get("/assets", {params: queryParams});
    return response.data;
};


export const getDataAssetById = async (assetId: string, branch?: string | null): Promise<DataAssetFullResponse> => {
    const response = await axiosInstance.get(`/assets/${assetId}`, {params: {branch}});
    return response.data;
};
