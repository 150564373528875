import React from 'react';
import {Box, Typography, debounce} from '@mui/material';
import { EntityRelatedAsset, RelationKind } from 'services/entities';
import theme from 'theme';


import { RelatedDataAsset, EntityAssetRelation as EntityAssetFieldsRelation } from 'components/add-edit-business-entity-component/add-edit-business-entities-related-datasets/models';

interface Props {
    relation: EntityRelatedAsset | RelatedDataAsset
}

const EntityAssetRelation: React.FC<Props> = ({relation}) => {
    let relationType;
    let fields: EntityAssetFieldsRelation[] = []
    let sql = relation.sql

    if(relation.hasOwnProperty("type")){
        let data = relation as EntityRelatedAsset
        relationType = data.type
        fields = data.fields.map(f=>({
            entityField: f.entity_field,
            dataAssetField: f.asset_field
        }))
    } else {
        let data = relation as RelatedDataAsset
        relationType = data.relationKind;
        fields = data.entityAssetRelations || []
    }

    return (
        <Box sx={{display: "flex", flexDirection: "column", gap: "12px"}}>
            {relationType === RelationKind.fields ?
                fields.map((r, i) => (
                    <Box key={`${r.entityField}-${r.dataAssetField}`} sx={{ display: 'flex', alignItems: 'center'}}>
                        <Typography variant="h5" sx={{ display: 'flex' }}>
                            <span
                                className="material-symbols-outlined"
                                style={{
                                    fontSize: '20px',
                                    color: theme.palette.customColor.purple,
                                    marginRight: '6px',
                                }}
                            >
                                package_2
                            </span>
                            {r.entityField}
                        </Typography>
                        <Box margin={'0 10px'}>=</Box>
                        <Typography variant="h5" sx={{ display: 'flex' }}>
                            <span
                                className="material-symbols-outlined"
                                style={{
                                    fontSize: '20px',
                                    color: theme.palette.customColor.purple,
                                    marginRight: '6px',
                                }}
                            >
                                database
                            </span>
                            {r.dataAssetField}
                        </Typography>
                    </Box>
                ))
            :
            (<Typography variant="body2">{sql}</Typography>)}
        </Box>
    );
};

export default EntityAssetRelation;
