import React from 'react';
import { Box, Chip, Stack, Typography } from '@mui/material';
import theme from 'theme';
import './aliases.component.scss';

interface AliasesProps {
    title: string;
    labels: string[];
    variant?: "light" | "dark";
}

const AliasesComponent: React.FC<AliasesProps> = ({ variant = 'light', title, labels }) => {
    const styles = variant === "dark"
        ? { backgroundColor: theme.palette.customColor.lightestGrey5, border: `1px solid ${theme.palette.customColor.lightGrey3}` }
        : { backgroundColor: "transparent", border: `1px solid ${theme.palette.customColor.lightGrey1}` };

    return (
        <Box>
            <Typography variant="h5" sx={{ color: theme.palette.customColor.dark, marginBottom: '12px' }}>
                {title}
            </Typography>

            <Stack direction="row" spacing={1} alignItems="center">
                {labels.map((label, index) => (
                    <Chip
                        key={index}
                        className="business-entities-user-aliases"
                        label={label}
                        color="primary"
                        variant="outlined"
                        sx={styles}
                    />
                ))}
            </Stack>
        </Box>
    );
};

export default AliasesComponent;