import React, {useEffect} from "react";
import Box from "@mui/material/Box";
import {DataGrid, GridToolbar, GridColDef} from "@mui/x-data-grid";
import "./business-entity-tabs-table.component.scss";
import BusinessEntitiesListTabsTableSearch
    from "./business-entity-tabs-table-search/business-entity-tabs-table-search.component";

interface BusinessEntitiesTabsTableProps {
    name: string;
    data: { [key: string]: any }[];
    columns: GridColDef[];
}

interface EntityData {
    id: number;
    entity?: string;
    entitySec?: string;
    relationship?: string;
    featureName?: string;
    type?: string;
    timeline?: string;
    dataAssetName?: string;
    column?: string;
    relation?: string;
}

const BusinessEntityTabsTable: React.FC<BusinessEntitiesTabsTableProps> = ({data, columns, name}) => {
    const [searchText, setSearchText] = React.useState<string>("");
    const [filteredRows, setFilteredRows] = React.useState<BusinessEntitiesTabsTableProps["data"]>(data);

    const handleSearchTextChange = (event?: React.ChangeEvent<HTMLInputElement>) => {
        let value: string;
        if (event) {
            value = event.target.value.toLowerCase();
            setSearchText(value);
        } else {
            value = searchText;
        }

        const filtered = data.filter(row =>
            columns.some(column =>
                String(row[column.field as keyof EntityData]).toLowerCase().includes(value)
            )
        );
        setFilteredRows(filtered);
    };

    useEffect(() => {
        handleSearchTextChange();
    }, [data]);

    return (
        <Box sx={{marginBottom: "24px"}}>
            <Box className="flex-box-space-between">
                <BusinessEntitiesListTabsTableSearch value={searchText} onChange={handleSearchTextChange}/>

            </Box>
            <DataGrid
                autoHeight
                className={`entity-data-grid ${name}-table`}
                columns={columns}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                getRowHeight={() => "auto"}
                hideFooter={true}
                rows={filteredRows}
                slots={{toolbar: GridToolbar}}
                slotProps={{
                    toolbar: {
                        showQuickFilter: false,
                    },
                }}
                sx={{
                    marginTop: "24px",
                    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {py: "15px"},
                }}
            />
        </Box>
    );
};

export default BusinessEntityTabsTable;
