import React from "react";
import {Box} from "@mui/material";
import AddFeaturesMeasures from "./add-features-data-content/add-features-measures/add-features-measures";
import AddFeaturesFilter from "./add-features-data-content/add-features-filter/add-features-filter";
import {DataAssetFullResponse} from "services/data-assets";
import {MetricType} from "../../feature-types";
import {getDataAssetName} from "../../data-asset-name";

interface AddFeaturesModalContentProps {
    selectedAsset: DataAssetFullResponse;
    metricDraft?: MetricType;
    onMetricUpdated: (metric: MetricType) => void;
}

const AddFeaturesMetricModalContent: React.FC<AddFeaturesModalContentProps> = ({
                                                                             selectedAsset,
                                                                             metricDraft,
                                                                             onMetricUpdated
                                                                         }) => {
    const handleFiltersUpdated = (filters: MetricType["filters"]) => {
        const newMetric: MetricType = {
            ...metricDraft as MetricType,
            dataAssetId: getDataAssetName(selectedAsset),
            filters: filters
        };
        onMetricUpdated(newMetric);
    };

    const handleMeasuresUpdated = (measures: MetricType["measures"]) => {
        const newMetric: MetricType = {
            dataAssetId: getDataAssetName(selectedAsset),
            filters: metricDraft?.filters,
            measures: measures
        }
        onMetricUpdated(newMetric);
    };

    return (
        <Box sx={{margin: "32px 0"}}>
            <AddFeaturesFilter
                assetColumns={selectedAsset["columns"]}
                selectedFilters={metricDraft?.filters}
                onFiltersUpdated={handleFiltersUpdated}
            />
            <AddFeaturesMeasures
                selectedAsset={selectedAsset}
                measures={metricDraft?.measures}
                onMeasuresUpdated={handleMeasuresUpdated}
            />
        </Box>
    );
};

export default AddFeaturesMetricModalContent;
