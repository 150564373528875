import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import BusinessEntityTabsTable
    from "./business-entity-tabs-table/business-entity-tabs-table.component";
import {EntityDetailResponse} from "types/index";
import {GridCellParams} from "@mui/x-data-grid";
import {Chip} from "@mui/material";
import theme from "theme";
import {Link} from "react-router-dom";
import BusinessEntityFeaturesModal from "../business-entity-features-modal/business-entities-features-modal.component";
import {useEffect, useState} from "react";
import {useGlobalContext} from "context/global-context";
import EntityAssetRelation from "components/shared/assets/entity-asset-relation/entity-asset-relation.component";
import { DataAssetColumn } from "services/data-assets";
import { AssetFeature } from "types/features";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export interface Props {
    entity: EntityDetailResponse | null,
    keyTableFields: DataAssetColumn[],
    onDataRefresh: () => void
}

function CustomTabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{py: 3}}>{children}</Box>}
        </div>
    );
}

function getTabProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export enum EntityTabs {
    Relationships = "Relationships",
    DataAssets = "DataAssets",
    Features = "Features"
}

const BusinessEntityTabs: React.FC<Props> = ({entity, keyTableFields, onDataRefresh}) => {
    const [value, setValue] = React.useState(0);
    const [features, setFeatures] = React.useState<{ id: string, featureName: string }[]>([]);
    const {isEditMode, drawerOpen} = useGlobalContext();
    const [isFeaturesModalOpen, setIsFeaturesModalOpen] = useState<boolean>(false);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleToggleAddFeatureModalOpen = (isOpen: boolean, shouldRefresh?: boolean) => {
        setIsFeaturesModalOpen(isOpen);
        if (shouldRefresh)
            onDataRefresh();
    };

    useEffect(() => {
        if (entity){
            const useFeatures = getFeatures();
            useFeatures.push(...keyTableFields.map(f=>({
                id: `key-table-${f.name}`,
                featureName: f.name
            })))
            setFeatures(useFeatures);
        }
    }, [entity, keyTableFields]);

    const getFeatures = (): { id: string, featureName: string }[] => {
        const features: { id: string, featureName: string }[] = [];
        (entity?.assetFeatures || []).forEach((assetFeature: AssetFeature, index: number) => {
            assetFeature.features.forEach((feature) => {
                features.push({id: `asset-${index}-${feature.name}`, featureName: feature.name});
            });
        });
        return features;
    };

    const relatedAssets = Object.keys(entity?.relatedAssets || {}).map((assetId, index) => {
        const [db, schema, tableName] = assetId.split(".")
        return {
            id: index,
            columns: entity?.relatedAssets[assetId],
            tableId: assetId,
            rawTableName: tableName
        }
    }).sort((a, b) => a.rawTableName.localeCompare(b.rawTableName))

    return (
        <Box sx={{width: "100%"}}>
            <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="Business entities"
                    sx={{
                        "& .MuiTab-root": {
                            minWidth: "auto",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "24px",
                            marginRight: "32px",
                            padding: "16px 0",
                        },
                        "& .MuiTabs-indicator": {
                            backgroundColor: theme.palette.customColor.purple,
                        },
                        "& .Mui-selected": {
                            color: `${theme.palette.customColor.purple} !important`,
                        },
                    }}
                >
                    <Tab label="Features" {...getTabProps(1)} />
                    <Tab label="Relationships" {...getTabProps(1)} />
                    <Tab label="Data assets" {...getTabProps(2)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={1}>
                <BusinessEntityTabsTable
                    name={EntityTabs.Relationships}
                    data={Object.entries(entity?.relatedEntities || {}).map(([key, value], index) => ({
                        id: index,
                        entity: key,
                        relationship: value,
                    }))}
                    columns={[
                        {
                            field: "entity",
                            headerName: "Entity",
                            flex: 1,
                            sortable: true,
                            headerClassName: "entity-columns--header"
                        },
                        {
                            field: "relationship",
                            headerName: "Relationship",
                            flex: 1,
                            sortable: false,
                            headerClassName: "entity-columns--header"
                        },
                    ]}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={0}>
                <Box sx={{position: "relative"}}>
                    <BusinessEntityTabsTable
                        name={EntityTabs.Features}
                        // TODO: connect to features retrived
                        data={features}
                        columns={[
                            {
                                field: "featureName",
                                headerName: "Feature name",
                                flex: 1,
                                sortable: true,
                                headerClassName: "entity-columns--header",
                                renderCell: (params: GridCellParams) => (
                                    <Chip
                                        label={String(params.value)}
                                        color="primary"
                                        variant="outlined"
                                        className="tag-cell"
                                    />
                                ),
                            }
                        ]}
                    />
                </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <BusinessEntityTabsTable
                    name={EntityTabs.DataAssets}
                    data={relatedAssets}
                    columns={[
                        {
                            field: "tableId",
                            headerName: "Data asset name",
                            flex: 1,
                            sortable: true,
                            headerClassName: "entity-columns--header",
                            renderCell: (params: GridCellParams) => (
                                <Link to={`/data-assets/${params.value}`}>
                                    <Chip
                                        label={String(params.value)}
                                        color="primary"
                                        variant="outlined"
                                        className="tag-cell"
                                    />
                                </Link>
                            ),
                        },
                        {
                            field: "dataAssetColumn",
                            headerName: "Data asset column",
                            flex: 1,
                            sortable: false,
                            headerClassName: "entity-columns--header",
                            renderCell: (params: GridCellParams) => {
                                return (
                                    <EntityAssetRelation relation={params.row.columns}/>
                                );
                            }
                        },
                    ]}
                />
            </CustomTabPanel>

            {entity && (<BusinessEntityFeaturesModal entity={entity} isModalOpen={isFeaturesModalOpen}
                                                     onClose={(shouldRefresh) => handleToggleAddFeatureModalOpen(false, shouldRefresh)}/>)}
        </Box>
    );
};

export default BusinessEntityTabs;
