// Generic types used for Features
export enum FeatureType {
    metric = "metric",
    firstLast = "first_last",
    formula = "formula"
}

// Metric feature
export interface MeasureType {
    alias?: string,
    name: string,
    definition: string,
    description?: string,
    selected?: boolean
    isNew?: boolean
}

export interface MetricFeature {
    name: string
    type: FeatureType,
    measures: MeasureType[]
}

// First Last feature
export interface FirstLastFeature {
    name: string
    type: FeatureType,
    options: {
        method: "first" | "last",
        sortBy: string
        field: string
    }
}

export interface AssetFeature {
    asset: string,
    features: (MetricFeature | FirstLastFeature)[]
}

export interface DerivedFeature {
    name: string,
    formula: string,
    features: {
        name: string,
        alias: string
    }[]
    
}

export function featureTypeToLabel(type: FeatureType): string {
    switch (type) {
        case FeatureType.firstLast:
            return "First / Last"; 
        case FeatureType.metric:
            return "Metric"
        case FeatureType.formula:
            return "Formula"
        default:
            return ""
    }
}