import React, {useEffect, useMemo, useState} from "react";
import {Box} from "@mui/material";
import AddFeaturesFilter from "./add-features-data-content/add-features-filter/add-features-filter";
import AddFeaturesFirstLastConfiguration
    from "./add-features-data-content/add-features-first-last-configuration/add-features-first-last-configuration";
import {DataAssetFullResponse} from "services/data-assets";
import {getDataAssetName} from "../../data-asset-name";
import {FirstLastDraftType, FirstLastFieldType, FirstLastType} from "../../feature-types";
import {useFirstLast} from "../../../../context/first-last-feature-context";
import {createFirstLastFeature} from "../../../../actions/first-last-reducer";
import ButtonComponent from "components/shared/button/button.component";
import theme from "theme";
import AddFeatureFirstLastFields
    from "./add-features-data-content/add-features-first-last-configuration/add-features-first-last-modal/add-first-last-fields";


interface AddFeaturesModalContentProps {
    selectedAsset: DataAssetFullResponse;
    onChange: (event: FirstLastType)=>void | Promise<void>
    onClose: () => void;
}

export type SelectedFieldType = { [key: string]: FirstLastFieldType };

const AddFeaturesFirstLastModalContent: React.FC<AddFeaturesModalContentProps> = ({
                                                                                      selectedAsset,
                                                                                      onChange,
                                                                                      onClose,
                                                                                  }) => {
    const {dispatchFirstLastAction} = useFirstLast();
    const dataAssetId = getDataAssetName(selectedAsset);
    const [selectedFields, setSelectedFields] = useState<SelectedFieldType>({});
    const [selectedFilters, setSelectedFilters] = useState<FirstLastType["filters"]>();
    const [sortMethod, setSortMethod] = useState<FirstLastDraftType["method"]>();
    const [sortBy, setSortBy] = useState<FirstLastDraftType["sortBy"]>();

    const getCurrentValue = (): FirstLastType => {
        return {
            dataAssetId: dataAssetId,
            method: sortMethod as FirstLastType["method"],
            sortBy: sortBy as FirstLastType["sortBy"],
            fields: Object.values(selectedFields) as FirstLastType["fields"],
            ...selectedFilters && {filters: selectedFilters},
        }
    }

    const isCreateEnabled = useMemo(() => {
        return Boolean(sortMethod) && Boolean(sortBy) && Object.keys(selectedFields).length > 0
    }, [sortMethod, sortBy, selectedFields]);

    useEffect(()=>{
        onChange(getCurrentValue())
    },[
        sortMethod, sortBy, selectedFields, selectedFilters
    ])

    const handleFiltersUpdated = (filters: FirstLastType["filters"]) => {
        setSelectedFilters(filters);
    };

    const deleteSelectedField = (fieldName: FirstLastFieldType["name"]) => {
        const updatedFields = JSON.parse(JSON.stringify(selectedFields));
        delete updatedFields[fieldName];
        setSelectedFields(updatedFields);
    };

    const updateFieldAlias = (fieldName: FirstLastFieldType["name"], alias: FirstLastFieldType["alias"]) => {
        const updatedFields = JSON.parse(JSON.stringify(selectedFields));
        updatedFields[fieldName].alias = alias;
        setSelectedFields(updatedFields);
    };

    const handleFieldsSelected = (fieldNames: FirstLastFieldType["name"][]) => {
        const selectedFields: SelectedFieldType = {};
        for (const name of fieldNames) {
            selectedFields[name] = {
                name: name,
            };
        }
        setSelectedFields(selectedFields);
    };

    const saveFeature = () => {
        if (isCreateEnabled) {
            dispatchFirstLastAction(createFirstLastFeature(getCurrentValue()));
        }
        onClose();
    };

    return (
        <Box>
            <Box sx={{margin: "32px 0"}}>
                <AddFeaturesFilter
                    assetColumns={selectedAsset.columns}
                    selectedFilters={selectedFilters}
                    onFiltersUpdated={handleFiltersUpdated}
                />
                <AddFeaturesFirstLastConfiguration
                    selectedAssetColumns={selectedAsset.columns}
                    onMethodUpdated={setSortMethod}
                    onSortByUpdated={setSortBy}
                />
                <AddFeatureFirstLastFields
                    selectedAssetColumns={selectedAsset.columns}
                    selectedFields={selectedFields}
                    onFieldsSelected={handleFieldsSelected}
                    onAliasUpdated={updateFieldAlias}
                    onSelectedFieldDeleted={deleteSelectedField}
                />

            </Box>
            <Box
                className="flex-box-end"
                position="sticky"
                bottom="0px"
                sx={{background: theme.palette.customColor.white}}
            >
                <ButtonComponent variant="text" label="Cancel" onClick={onClose}/>
                <ButtonComponent
                    disabled={!isCreateEnabled}
                    variant="contained"
                    label="Create first / last"
                    onClick={saveFeature}
                />
            </Box>
        </Box>
    );
};

export default AddFeaturesFirstLastModalContent;
