import React, {useEffect, useState} from "react";
import {Box, Container, Divider, Typography} from "@mui/material";
import BreadcrumbsComponent from "../shared/breadcrumbs/breadcrumbs.component";
import AliasesComponent from "../shared/aliases/aliases.component";
import theme from "theme";
import DataAssetDescription from "./data-asset-description/data-asset-description.component";
import DataAssetTabs from "./data-asset-tabs/data-asset-tabs.component";
import {useGlobalContext} from "context/global-context";
import {DataAssetFullResponse, getDataAssetById} from "services/data-assets";
import {useParams} from "react-router-dom";
import LoadingSpinner from "components/shared/LoadingSpinner";
import {InfoIcon} from "lucide-react";

const DataAssetComponent: React.FC = () => {
    const {assetId} = useParams<{ assetId: string }>();
    const {drawerOpen, selectedGitBranch} = useGlobalContext();
    const [dataAsset, setDataAsset] = useState<DataAssetFullResponse | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchDataAsset = async (assetId: string) => {
        setErrorMessage(null);
        setIsLoading(true);
        try {
            const response = await getDataAssetById(assetId, selectedGitBranch);
            setDataAsset(response);
        } catch (error: any) {
            setErrorMessage(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (assetId) fetchDataAsset(assetId);
    }, [assetId]);

    return (
        <Container sx={{height: "100%"}}>
            <Box
                className="flex-box-start"
                flexDirection="column"
                sx={{margin: "41px 50px 0 50px"}}
            >
                <Box sx={{width: drawerOpen ? "calc(100% - 125px)" : "100%", margin: "0 0 25px 0"}}>
                    <BreadcrumbsComponent linkTo="/data-assets" linkText="Data assets"
                                          secondText={dataAsset?.name || ""}/>
                    <Box margin="32px 0 25px 0">
                        {errorMessage && (
                            <Box className="flex-box-row" margin="20px 0">
                                <InfoIcon/>
                                <Typography variant="subtitle2" paddingLeft="10px">
                                    We couldn't load data asset due to an error: {errorMessage}
                                </Typography>
                            </Box>
                        )}
                        {dataAsset && !errorMessage && (
                            <>
                                <Typography
                                    variant="h2"
                                    sx={{
                                        fontSize: "42px",
                                        color: theme.palette.customColor.dark,
                                        letterSpacing: "-0.84px",
                                        lineHeight: "46px",
                                        margin: "20px 0",
                                    }}
                                >
                                    {dataAsset.name}
                                </Typography>
                                {dataAsset.description && <DataAssetDescription description={dataAsset.description}/>}


                                <Box className="flex-box-start" sx={{margin: "24px 0 40px 0"}}>
                                        <AliasesComponent variant="dark" title="db.schema" labels={[`${dataAsset.db}.${dataAsset.schema}`.toLowerCase()]}/>

                                        <Divider orientation="vertical" sx={{
                                            backgroundColor: theme.palette.customColor.lightGrey1,
                                            width: "1px",
                                            height: "58px",
                                            border: 0,
                                            margin: "0 40px"
                                        }}/>

                                        <AliasesComponent variant="dark" title="Key" labels={dataAsset.keys}/>

                                        <Divider orientation="vertical" sx={{
                                            backgroundColor: theme.palette.customColor.lightGrey1,
                                            width: "1px",
                                            height: "58px",
                                            border: 0,
                                            margin: "0 40px"
                                        }}/>

                                        <AliasesComponent variant="dark" title="Business Key" labels={dataAsset.businessKeys}/>

                                        <Divider orientation="vertical" sx={{
                                            backgroundColor: theme.palette.customColor.lightGrey1,
                                            width: "1px",
                                            height: "58px",
                                            border: 0,
                                            margin: "0 40px"
                                        }}/>

                                        <AliasesComponent variant="light" title="Related entities:" labels={dataAsset.entities}/>
                                </Box>

                                <DataAssetTabs asset={dataAsset}/>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
            <LoadingSpinner open={isLoading}/>
        </Container>
    );
};

export default DataAssetComponent;