import React, { useState } from "react";
import { Box } from "@mui/material";
import ButtonComponent from "../../../shared/button/button.component";
import AddFeaturesFormulas from "./add-features-data-content/add-features-formulas/add-features-formulas";
import theme from "theme";
import { useGlobalContext } from "context/global-context";
import { useParams } from "react-router-dom";
import { FormulaType } from "components/add-features-component/feature-types";
import SqlView from "components/shared/sql-view/sql-view.component";
import { TemplateType, preview } from "services/templates";

export interface AddFeaturesFormulaModalDataProps {
    onFeatureSaved: (feature: FormulaType) => void
    handleCloseModal: ()=>void
}

const AddFeaturesFormulaModalData: React.FC<AddFeaturesFormulaModalDataProps> = ({
    onFeatureSaved,
    handleCloseModal
}) => {
    const {selectedGitBranch} = useGlobalContext();
    const {entityId} = useParams<{ entityId: string }>();
    const [isCreateFormulaEnabled, setCreateFormulaEnabled] = useState(false);
    const [formulaFeature, setFormulaFeature] = useState<FormulaType>();
    const [sql, setSql] = useState<string>("");

    const onDataChanged = async (data: FormulaType) => {
        setFormulaFeature(data);
        const { sql: newSql } = await preview(selectedGitBranch, TemplateType.formula, {
            entity_id: entityId as string, 
            name: data.name,
            sql: data.sql,
            features: data.features
        })
        setSql(newSql)
    }

    return (
        <Box sx={{display:"flex", height: "100%", width: "100%"}}>
                <Box sx={{width: "450px", flexShrink: 0}}>
                    <SqlView sql={sql} />
                </Box>
                <Box className="flex-box-col" sx={{width: "100%"}}>
                    <AddFeaturesFormulas
                        onChange={onDataChanged}
                        setCreateFormulaEnabled={setCreateFormulaEnabled}
                    />

                    <Box className="flex-box-end" position="sticky" bottom="-24px" padding="24px 0" sx={{ background: theme.palette.customColor.white }}>
                        <ButtonComponent variant="text" label="Cancel" onClick={handleCloseModal} />
                        <ButtonComponent
                            disabled={!isCreateFormulaEnabled}
                            variant="contained"
                            label="Create formula"
                            onClick={() => {
                                onFeatureSaved(formulaFeature as FormulaType);
                                handleCloseModal()
                            }}
                        />
                    </Box>
            </Box>
        </Box>
    );
};

export default AddFeaturesFormulaModalData;