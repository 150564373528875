import {FirstLastType} from "../components/add-features-component/feature-types";
import {FirstLastAction} from "../actions/first-last-reducer";

export const firstLastReducer = (state: FirstLastType[], action: FirstLastAction): FirstLastType[] => {
    switch (action.type) {
        case "CREATE":
            return [...state, action.payload];
        case "DELETE":
            const keys = action.payload.id.split("-");
            const fieldName = keys.pop();
            const featureName = keys.join("-");
            const filteredFeatures: any[] = [];
            for (const feature of state) {
                if (feature.id !== featureName) continue;
                if (feature.fields) {
                    const fields = feature.fields.filter((f) => ![f.name, f.alias].includes(fieldName));
                    if (fields.length) {
                        filteredFeatures.push({...feature, fields});
                    }
                }
            }
            return filteredFeatures;
        case "DELETE_ALL":
            return [];
        default:
            return state;
    }
};
