import React, {useEffect, useMemo, useState} from "react";
import {Box} from "@mui/material";
import AddEditBusinessEntitiesRelatedDatasetsTableFirst
    from "./add-edit-business-entities-related-datasets-table-first/add-edit-business-entities-related-datasets-table-first.component";
import AddEditBusinessEntitiesRelatedDatasetsTableSecond
    from "./add-edit-business-entities-related-datasets-table-second/add-edit-business-entities-related-datasets-table-second.component";
import {AddEditBusinessEntityStepProps} from "../add-edit-business-entities-step-props";
import {DataAssetResponse, getDataAssetById} from "services/data-assets";
import {RelatedDataAsset} from "./models";
import {useForm} from "react-hook-form";

export interface RelatedAssetsStepFormData {
    assets: RelatedDataAsset[];
}

interface RelatedAssetsStepProps extends AddEditBusinessEntityStepProps<RelatedAssetsStepFormData> {
    dimTable: string;
}

const AddEditBusinessEntitiesRelatedDatasets: React.FC<RelatedAssetsStepProps> = ({
                                                                                      handleFormValues,
                                                                                      dimTable,
                                                                                      stepFormValues,
                                                                                      formRef,
                                                                                  }) => {
    const defaultValue = stepFormValues || {
        assets: [{id: 0, asset: null, assetFields: []}]
    };
    const [entityFields, setEntityFields] = useState<string[]>([]);

    const {
        handleSubmit,
        formState: {errors},
        setValue
    } = useForm({defaultValues: defaultValue});

    const fetchDataAsset = async (assetId: string) => {
        try {
            const dimTableData = await getDataAssetById(assetId);
            setEntityFields(dimTableData?.columns.map(c => c.name) || []);
        } catch (error: any) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        if (dimTable.length) fetchDataAsset(dimTable);
    }, [dimTable]);

    const onSubmit = (data: any) => {
        const values = {
            assets: data.assets
        };
        handleFormValues(values);
    };

    const relatedAssetsChanged = (data: RelatedAssetsStepFormData) => {
        setValue("assets", data.assets);
    };

    return (
        <Box component="form" ref={formRef} onSubmit={handleSubmit(onSubmit)} className="flex-box-col-center"
             sx={{maxWidth: "1005px", width: "100%"}}>
            <AddEditBusinessEntitiesRelatedDatasetsTableFirst
                initialValues={defaultValue} onChange={relatedAssetsChanged} entityFields={entityFields}/>

            {/* <AddEditBusinessEntitiesRelatedDatasetsTableSecond /> */}
        </Box>
    );
};

export default AddEditBusinessEntitiesRelatedDatasets;
