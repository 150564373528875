import React from "react";
import {Box, Typography} from "@mui/material";
import {InfoIcon} from "lucide-react";
import theme from "theme";
import BusinessEntitiesInfo from "../business-entities-info/business-entities-info.component";
import {EntityResponse} from "types/index";
import ButtonComponent from "../../shared/button/button.component";
import SearchComponent from "../../shared/search/search.component";

interface BusinessEntitiesListProps {
    entities: EntityResponse[];
    isEditMode: boolean;
    openAddEditEntityModal: (entityId?: string) => void;
    searchValue: string;
    setSearchValue: (value: string) => void;
    filteredEntities: EntityResponse[];
    errorMessage: string | null;
    isLoading: boolean;
    entityId?: string;
}

const BusinessEntitiesList: React.FC<BusinessEntitiesListProps> = ({
                                                                       entities,
                                                                       isEditMode,
                                                                       openAddEditEntityModal,
                                                                       searchValue,
                                                                       setSearchValue,
                                                                       filteredEntities,
                                                                       errorMessage,
                                                                       isLoading,
                                                                       entityId,
                                                                   }) => {
    return (
        <Box className="flex-box-col-start" sx={{
            width: "307px",
            borderRight: `1px solid ${theme.palette.customColor.lightGrey1}`,
            boxShadow: "0 4px 13px 0 rgba(97, 97, 97, 0.02)",
            paddingRight: "24px",
        }}>
            <Box className="flex-box-center-space-between" sx={{width: "100%", margin: "32px 0 24px 0"}}>
                <Typography
                    variant="subtitle1"
                    sx={{color: theme.palette.customColor.dark, fontWeight: "600"}}
                >
                    {isEditMode ? "Edit Business Entities" : "Business Entities"}
                </Typography>
                {isEditMode && (
                    <ButtonComponent
                        onClick={() => openAddEditEntityModal()}
                        variant="contained"
                        label=""
                        startIcon={
                            <span
                                className="material-symbols-outlined"
                                style={{fontSize: "24px"}}
                            >
                add
            </span>
                        }
                        sx={{
                            minWidth: "auto",
                            padding: "9px",
                            "& .MuiButton-startIcon": {
                                margin: 0,
                            },
                        }}
                    />
                )}
            </Box>

            {errorMessage && !entities.length && (
                <Box className="flex-box-align-center" margin="20px 0">
                    <InfoIcon/>
                    <Typography variant="subtitle2" paddingLeft="10px">
                        We couldn't load entities due to an error
                    </Typography>
                </Box>
            )}

            {entities.length ? (
                <Box className="flex-box-col" sx={{width: "100%"}}>
                    <SearchComponent searchValue={searchValue} setSearchValue={setSearchValue}/>
                    <Box className="flex-box-col" sx={{gap: "12px", marginTop: "24px"}}>
                        {filteredEntities.map((item, index) => (
                            <BusinessEntitiesInfo
                                key={index}
                                name={item.name}
                                isEditMode={isEditMode}
                                isActive={entityId === item.name}
                            />
                        ))}
                    </Box>
                </Box>
            ) : !errorMessage && !isLoading && (
                <Box className="flex-box-align-center" margin="20px 0">
                    <InfoIcon/>
                    <Typography variant="subtitle2" paddingLeft="10px">
                        No entities found
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default BusinessEntitiesList;