import React, {useEffect, useState} from "react";
import {Box, Typography, Modal, Button, Popover} from "@mui/material";
import "./add-features-measures-modal.scss";
import theme from "theme";
import ButtonComponent from "components/shared/button/button.component";
import AddFeaturesMeasuresStepCreate from "./add-features-measures-step-create/add-features-measures-create";
import {ArrowLeftSymbols} from "assets/icons";
import {containsText} from "utils/contains-text";
import {DataAssetFullResponse} from "services/data-assets";
import {DataAssetName} from "../../../../../data-asset-name";
import {MetricType} from "../../../../../feature-types";
import SearchComponent from "components/shared/search/search.component";
import { MeasureType } from "types/features";

interface AddFeaturesMeasuresPopoverProps {
    anchorEl: HTMLButtonElement | null;
    onClose: () => void;
    selectedAsset: DataAssetFullResponse;
    measuresProp?: MeasureType[];
    setMeasuresProp: (measuresDatas: MeasureType[]) => void;
}

const AddFeaturesMeasuresPopover: React.FC<AddFeaturesMeasuresPopoverProps> = ({
    anchorEl,
    onClose,
    selectedAsset,
    measuresProp = [],
    setMeasuresProp,
}) => {
    const [searchValue, setSearchValue] = useState("");
    const [showCreateSection, setShowCreateSection] = useState(false);
    const [checkedCount, setCheckedCount] = useState(0);
    const [addMeasureLabel, setAddMeasureLabel] = useState("Add measure");
    
    const [measures, setAllMeasures] = useState<MeasureType[]>((selectedAsset.measures || []).map(m=>({
        name: m.name,
        definition: m.sql,
        description: m.description,
    })));
    const [filteredMeasures, setFilteredMeasures] = useState<MetricType["measures"]>();
    
    const handleOpenMeasuresStep = () => {
        setShowCreateSection(!showCreateSection);
    };

    const close = (save:boolean = false) => {
        if(save)
            setMeasuresProp(measures.filter(m=>m.selected)); // update selected measures in parent state
        onClose();
        setSearchValue("");
    }

    const toggleMeasureSelectedState = (measureName: MeasureType["name"]) => {
        const updatedMeasures: MeasureType[] = measures.map(measure=>{
            if (measure.name === measureName) measure.selected = !measure.selected
            return measure
        })
        setAllMeasures(updatedMeasures)
    }

    const handleNewMeasureCreated = (measure: MeasureType) => {
        setAllMeasures([...measures, measure]) // handle parent state
    }

    const onCreateMeasureClose = (result?: any)=>{
        setShowCreateSection(false)
    }

    useEffect(() => {
        const count = measures.filter((m) => m.selected === true).length;
        setCheckedCount(count);
        setAddMeasureLabel(count > 0 ? `Add measure (${count})` : "Add measure");
        setFilteredMeasures(
            measures.filter((m) => !searchValue ? true : containsText(m.name, searchValue)) as MetricType["measures"]
        );
    }, [searchValue, measures]);

    return (
        <Box>
            <Popover id="measures-select" 
            open={Boolean(anchorEl)} 
            anchorEl={anchorEl} 
            onClose={onClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}>
            <Box sx={{padding: "16px"}}
                
            >
                        <Box className="flex-box-align-center">
                            <SearchComponent
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                            />
                            <ButtonComponent
                                onClick={handleOpenMeasuresStep}
                                variant="textPurple"
                                label="+ Create"
                                sx={{
                                    fontSize: "14px",
                                    lineHeight: "22px",
                                    margin: "0 0 0 16px",
                                    padding: 0,
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                maxHeight: "390px",
                                overflowY: "auto",
                                marginTop: "8px",
                            }}
                        >
                            {filteredMeasures?.map((measure, index) => (
                                <Box
                                    key={measure.name}
                                    className="flex-box-align-center"
                                    sx={{
                                        cursor: "pointer",
                                        borderRadius: "10px",
                                        backgroundColor: Boolean(measure.selected)
                                            ? theme.palette.customColor.purpleLight
                                            : "transparent",
                                        marginBottom: "3px",
                                        padding: "8px 16px",
                                        "&:last-child": {
                                            marginBottom: 0,
                                        },
                                    }}
                                    onClick={() => toggleMeasureSelectedState(measure.name)}
                                >
                                    <span
                                        className="material-symbols-outlined"
                                        style={{
                                            color: Boolean(measure.selected)
                                                ? theme.palette.customColor.purple
                                                : "inherit",
                                        }}
                                    >
                                        match_case
                                    </span>
                                    <Box sx={{ml: "10px"}}>
                                        <Typography
                                            variant="subtitle2"
                                            sx={{
                                                color: Boolean(measure.selected)
                                                    ? theme.palette.customColor.purple
                                                    : theme.palette.customColor.darkGrey,
                                                mb: "2px",
                                            }}
                                        >
                                            {measure.name}
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            sx={{color: theme.palette.customColor.grey}}
                                        >
                                            {measure.definition}
                                        </Typography>
                                    </Box>
                                    {Boolean(measure.selected) && (
                                        <span
                                            className="material-symbols-outlined"
                                            style={{
                                                marginLeft: "auto",
                                                color: theme.palette.customColor.purple,
                                            }}
                                        >
                                            check
                                        </span>
                                    )}
                                </Box>
                            ))}
                            {(searchValue && filteredMeasures?.length === 0) &&
                                <Typography variant="body2">No measure found</Typography>
                            }
                        </Box>
                        <Box className="flex-box-end" sx={{marginTop: "8px"}}>
                            <ButtonComponent
                                variant="text"
                                label="Cancel"
                                onClick={()=>close()}
                                sx={{fontSize: "14px", lineHeight: "22px"}}
                            />
                            <ButtonComponent
                                disabled={checkedCount === 0}
                                variant="contained"
                                label={addMeasureLabel}
                                onClick={()=>close(true)}
                                sx={{fontSize: "14px", lineHeight: "22px"}}
                            />
                        </Box>
            </Box>
        </Popover>
        <Modal
            key="create-measure-modal"
            open={showCreateSection}
            onClose={onCreateMeasureClose}
            className="container-modal-bg-none"
        >
                    <Box className=" container-modal-small">
                        <Button className="modal-close" onClick={()=>onCreateMeasureClose()}>
                            <span className="material-symbols-outlined modal-close-icon">
                                close
                            </span>
                        </Button>

                        <Box>
                            <Box className="flex-box-align-center">
                                <ButtonComponent
                                    onClick={handleOpenMeasuresStep}
                                    variant="text"
                                    label={<ArrowLeftSymbols/>}
                                    sx={{
                                        minWidth: "auto",
                                        fontSize: "14px",
                                        lineHeight: "22px",
                                        margin: 0,
                                        padding: 0,
                                    }}
                                />
                                <Typography
                                    variant="h4"
                                    sx={{width: "100%", textAlign: "center", fontWeight: 600}}
                                >
                                    Create Measure
                                </Typography>
                            </Box>

                            <Box sx={{margin: "24px 0"}}>
                                <DataAssetName asset={selectedAsset}/>
                            </Box>
                        </Box>
                        <AddFeaturesMeasuresStepCreate
                            onClose={() => setShowCreateSection(false)}
                            onNewMeasureCreated={handleNewMeasureCreated}
                        />
                    </Box>
        </Modal>
        </Box>
        
    );
};

export default AddFeaturesMeasuresPopover;