import {Box, Typography} from "@mui/material";
import {DataGrid, GridColDef, GridToolbar} from "@mui/x-data-grid";
import EntityToAssetRelations
    from "components/add-edit-business-entity-component/add-edit-business-entities-related-datasets/entity-asset-relation-wrapper.component";
import React, {useState} from "react";
import {useFieldArray, useForm} from "react-hook-form";
import {DataAssetResponse, getDataAssetById} from "services/data-assets";
import {RelationKind} from "services/entities";
import theme from "theme";
import ButtonComponent from "../../../shared/button/button.component";
import AddBusinessEntitiesRelatedDatasetsSelect
    from "../add-edit-business-entities-related-datasets-select/add-edit-business-entities-related-datasets-select.component";
import {EntityAssetRelation, RelatedDataAsset} from "../models";

interface RelatedAssetsTableData {
    assets: RelatedDataAsset[];
}

interface RelatedAssetsTableProps {
    entityFields: string[];
    initialValues: RelatedAssetsTableData;
    onChange: (value: RelatedAssetsTableData) => void;
}


const AddEditBusinessEntitiesRelatedDatasetsTableFirst: React.FC<RelatedAssetsTableProps> = ({
                                                                                                 entityFields,
                                                                                                 initialValues,
                                                                                                 onChange
                                                                                             }) => {
    const [idCounter, setIdCounter] = useState<number>(initialValues?.assets?.length || 0);
    const {
        control,
        formState: {errors},
        getValues,
    } = useForm({
        defaultValues: initialValues || {
            assets: [{id: idCounter, asset: "", assetFields: []}]
        }
    });
    const {fields: assets, append: addAsset, remove: removeAsset, update: updateAsset} = useFieldArray({
        control,
        name: "assets"
    });

    const updateParent = () => {
        onChange(getValues());
    };

    const handleAddClick = () => {
        addAsset({id: idCounter + 1, asset: null, assetFields: []});
        setIdCounter(idCounter + 1);
        updateParent();
    };

    const getRowIndex = (relatedAsset: RelatedDataAsset): number => {
        return getValues("assets").findIndex(asset => asset.id === relatedAsset.id);
    };

    const handleDeleteClick = (relatedAsset: RelatedDataAsset) => {
        const index = getRowIndex(relatedAsset);
        if (index !== -1) {
            removeAsset(index);
            updateParent();
        }
    };

    const fetchDataAsset = async (assetId: string) => {
        try {
            return await getDataAssetById(assetId);
        } catch (error: any) {
            console.log(error.message);
        }
    };

    const updateTable = async (relatedAsset: RelatedDataAsset, newValue: DataAssetResponse) => {
        const index = getRowIndex(relatedAsset);

        if (index !== -1) {
            const asset = await fetchDataAsset(newValue.id);
            const columnNames = asset?.columns?.map(c => c.name) || [];
            updateAsset(index, {id: index, asset: newValue, assetFields: columnNames});
            updateParent();
        }
    };

    const updateTableRelations = async (
        relatedAsset: RelatedDataAsset,
        relationKind: RelatedDataAsset["relationKind"],
        relations: RelatedDataAsset["entityAssetRelations"] | RelatedDataAsset["sql"]
    ) => {
        const index = getRowIndex(relatedAsset);

        if (index !== -1) {
            if (relationKind === RelationKind.fields) {
                updateAsset(index, {
                    ...getValues("assets")[index],
                    relationKind,
                    entityAssetRelations: relations as RelatedDataAsset["entityAssetRelations"]
                });
            }
            if (relationKind === RelationKind.sql) {
                updateAsset(index, {
                    ...getValues("assets")[index],
                    relationKind,
                    sql: relations as RelatedDataAsset["sql"]
                });
            }
            updateParent();
        }
    };

    const getTableKeyColumn = (relatedAsset: RelatedDataAsset): string => {
        if (!relatedAsset.asset) return "Choose data asset first";

        return relatedAsset.asset.keys && relatedAsset.asset.keys.filter(a => a).length ? relatedAsset.asset.keys.join(", ") : "-";
    };

    const columns: GridColDef[] = [
        {
            field: "nameData",
            headerName: "Name",
            flex: 1.4,
            sortable: false,
            renderCell: (params) => {
                return (
                    <AddBusinessEntitiesRelatedDatasetsSelect
                        onChange={(_e, newValue) => newValue && updateTable(params.row, newValue)}
                        name={`table_pic_${params.row.id}`}
                        initialValue={params.row.asset}
                    />
                );
            },
        },
        {
            field: "key",
            headerName: "Key",
            flex: 0.9,
            sortable: false,
            renderCell: (params) => <Box className={params.row.asset ? "" : "dim-table-column-sec"}>
                <Typography
                    variant="h5"
                    sx={{
                        color: params.row.asset ? theme.palette.customColor.dark : theme.palette.customColor.lavenderGrey,
                        lineHeight: "22px",
                    }}
                >
                    {getTableKeyColumn(params.row)}
                </Typography>
            </Box>,
        },
        {
            field: "entityAssetRelations",
            headerName: "Relation (<entity>)",
            flex: 0.9,
            sortable: false,
            renderCell: (params) => (
                <Box className={params.row.asset ? "" : "dim-table-column-sec"}>
                    {!params.row.asset ?
                        <Typography
                            variant="h5"
                            sx={{
                                color: params.row.asset ? theme.palette.customColor.dark : theme.palette.customColor.lavenderGrey,
                                lineHeight: "22px",
                            }}
                        >
                            {getTableKeyColumn(params.row)}
                        </Typography>
                        : <EntityToAssetRelations
                            entityFields={entityFields}
                            dataAssetId={params.row.asset?.id}
                            relationKind={params.row.relationKind}
                            sql={params.row.sql}
                            entityAssetRelations={params.row.entityAssetRelations}
                            setFieldsRelations={
                                (relations: EntityAssetRelation[]) =>
                                    updateTableRelations(params.row, RelationKind.fields, relations)}
                            setSqlRelations={
                                (sql: RelatedDataAsset["sql"]) =>
                                    updateTableRelations(params.row, RelationKind.sql, sql)}
                        />
                    }
                </Box>),
        },
        {
            field: "deleteAction",
            headerName: "",
            width: 30,
            sortable: false,
            renderCell: (params) => (
                <span
                    className="material-symbols-outlined"
                    style={{cursor: "pointer"}}
                    onClick={() => handleDeleteClick(params.row)}
                >
                    delete
                </span>
            ),
        },
    ];

    return (
        <Box className="flex-box-col-center" sx={{maxWidth: "1005px", width: "100%"}}>
            <Box className="dim-table-style" sx={{width: "100%", marginTop: "32px"}}>
                {assets.length > 0 && (
                    <DataGrid
                        autoHeight={true}
                        className="entity-data-grid"
                        columns={columns}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        disableAutosize={false}
                        getRowHeight={() => "auto"}
                        hideFooter={true}
                        initialState={{
                            sorting: {
                                sortModel: [{field: "nameData", sort: "asc"}],
                            },
                        }}
                        rows={getValues("assets")}
                        sx={{
                            margin: "16px 0 0 0",
                            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {py: "15px"},
                        }}
                        slots={{toolbar: GridToolbar}}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: false,
                            },
                        }}
                    />
                )}
                <ButtonComponent
                    onClick={handleAddClick}
                    variant="textPurple"
                    label="+ Add new"
                    sx={{fontSize: "14px", marginTop: "16px", paddingLeft: 0}}
                />
            </Box>
        </Box>
    );
};

export default AddEditBusinessEntitiesRelatedDatasetsTableFirst;
