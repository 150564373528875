import React from "react";
import {Box, Typography} from "@mui/material";
import theme from "theme";

const BusinessEntitiesListKeyAsset: React.FC<{keyTableId: string}> = ({
    keyTableId
}) => {
    return (
        <Box>
            <Typography variant="h5"
                        sx={{color: theme.palette.customColor.slateGrey, marginBottom: "12px"}}>
                Key data asset
            </Typography>

            <Typography variant="subtitle2"
                        sx={{color: theme.palette.customColor.black}}>
                {keyTableId}
            </Typography>
        </Box>
    );
};

export default BusinessEntitiesListKeyAsset;
