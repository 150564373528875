import React from "react";
import {Box, Chip, Stack, Typography} from "@mui/material";
import theme from "theme";
import {EntityDetailResponse} from "types/index";

interface Props {
    aliases: EntityDetailResponse["aliases"];
}

const BusinessEntitiesListAliases: React.FC<Props> = ({aliases}) => {
    return (
        <Box>
            <Typography variant="h5"
                        sx={{color: theme.palette.customColor.slateGrey, marginBottom: "10px"}}>
                Also known as
            </Typography>

            <Stack direction="row" spacing={1} alignItems="center">
                {Object.values(aliases).map((alias, index) => (
                    <Chip
                        key={index}
                        label={String(alias)}
                        color="primary"
                        variant="outlined"
                        className="business-entities-user-aliases"
                    />
                ))}
            </Stack>
        </Box>
    );
};

export default BusinessEntitiesListAliases;
