import React, {useState, useEffect} from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {GitBranch} from "lucide-react";
import theme from "theme";
import {getGitBranches} from "services/entities";
import {useGlobalContext} from "context/global-context";
import {Autocomplete, TextField} from "@mui/material";
import CustomSwitch from "components/shared/switch/switch.component";

const HeaderSwitch = () => {
    const {
        isEditMode,
        toggleEditMode,
        selectedGitBranch,
        setSelectedGitBranch
    } = useGlobalContext();
    const [branches, setBranches] = useState<string[]>([]);

    const handleChange = () => {
        toggleEditMode((isEditMode) => !isEditMode);
    };

    const handleBranchSelect = (event: any, branch: string | null) => {
        if (branch !== selectedGitBranch) setSelectedGitBranch(branch);
    };

    useEffect(() => {
        const getBranches = async () => {
            try {
                const data = await getGitBranches();
                setBranches(data);
                // initialize selected branch as the first one from the list
                if (!selectedGitBranch && data?.length > 0) setSelectedGitBranch(data[0]);
            } catch (error: any) {
                console.error(error);
            }
        };
        if (branches.length === 0) getBranches();
    }, [branches]);

    return (
        <Box className="flex-box-align-center" sx={{marginLeft: "40px"}}>
            <CustomSwitch checked={isEditMode}
                          onChange={handleChange}/>
            <Typography variant="h5" noWrap sx={{color: theme.palette.customColor.grey, ml: "16px"}}>
                Edit mode
            </Typography>
            {isEditMode && (
                <Autocomplete
                    options={branches}
                    className="branch-select-component"
                    value={selectedGitBranch}
                    onChange={handleBranchSelect}
                    renderOption={(props, option) => {
                        const {key, ...optionProps} = props;
                        return (
                            <Box
                                key={key}
                                component="li"
                                {...optionProps}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        backgroundColor: theme.palette.customColor.lightestGrey2,
                                        borderRadius: "8px",
                                        marginRight: "10px",
                                        padding: "8px",
                                    }}
                                >
                                    <GitBranch size="18"/>
                                </Box>
                                <Typography variant="subtitle2">{option}</Typography>
                            </Box>
                        )
                    }}
                    renderInput={(params) => (
                        <Box sx={{display: "flex"}}>
                            <Box
                                sx={{
                                    display: "flex",
                                    backgroundColor: theme.palette.customColor.lightestGrey2,
                                    borderRadius: "8px",
                                    marginRight: "10px",
                                    padding: "8px",
                                    "& svg": {
                                        marginRight: 0,
                                    }
                                }}
                            >
                                <GitBranch size="18"/>
                            </Box>
                            <TextField
                                {...params}
                                className="branch-option flex-box-align-center"
                                fullWidth
                                variant="standard"
                            />
                        </Box>
                    )}
                />
            )}
        </Box>
    );
};

export default HeaderSwitch;
