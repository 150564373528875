import React, {useState} from "react";
import {Autocomplete, Box, Button, TextField} from "@mui/material";
import {DataGrid, GridToolbar, GridColDef} from "@mui/x-data-grid";
import ButtonComponent from "../../../shared/button/button.component";
import EntitiesSelector from "components/shared/entities/entities-selector/entities-selector.component";
import { EntityResponse } from "types";
import { useFieldArray, useForm } from "react-hook-form";
import { ArrowDownSymbols } from "assets/icons";

export interface EntityData {
    id: number;
    entity?: string;
    relationship?: string;
}

export interface RelatedEntity{
    id: number,
    entity: EntityResponse | null
    relationship?: string
}

export interface RelatedEntitiesTableData{
    entities: RelatedEntity[]
}

interface RelatedEntitiesTableProps{
    initialValues: RelatedEntitiesTableData
    onChange: (value: RelatedEntitiesTableData) => void
}

const AddEditBusinessEntitiesRelatedTableFirst: React.FC<RelatedEntitiesTableProps> = ({
    initialValues,
    onChange
}) => {
    const [idCounter, setIdCounter] = useState<number>(initialValues?.entities?.length || 0);
    const {
        register,
        handleSubmit,
        control,
        formState: {errors},
        getValues,
    } = useForm({defaultValues: initialValues || {
        entities: [{id:idCounter, entity: null}]
    }});
    const {fields: entities, append: addEntity, remove: removeEntity, update: updateEntity} = useFieldArray({control, name:"entities"})
    
    const relationships = [
        {label: "One to one", value: "1:1"},
        {label: "One to many", value: "1:n"},
        {label: "Many to many", value: "n:n"}
    ]

    const updateParent = () => {
        onChange(getValues())
    }

    const handleAddClick = () => {
        addEntity({id: idCounter+1, entity: null})
        setIdCounter(idCounter+1)
        updateParent()
    };

    const getRowIndex = (relatedEntity: RelatedEntity): number => {
        if (!relatedEntity) return -1
        return getValues("entities").findIndex(entity=> entity.id === relatedEntity.id)
    }

    const handleDeleteClick = (relatedEntity: RelatedEntity) => {
        const index = getRowIndex(relatedEntity)
        if (index !== -1){
            removeEntity(index)
            updateParent()
        }
    };

    const entityChanged = (relatedEntity: RelatedEntity, newValue: EntityResponse | null) => {
        const index = getRowIndex(relatedEntity)
        if (index !== -1){
            updateEntity(index, {...getValues("entities")[index], entity: newValue})
            updateParent()
        }
    };

    const entityRelationChanged = (relatedEntity: RelatedEntity, relationship: {value: string, label:string} | null) => {
        const index = getRowIndex(relatedEntity)
        if (index !== -1){
            updateEntity(index, {...getValues("entities")[index], relationship: relationship?.value})
            updateParent()
        }
    };

    const columns: GridColDef[] = [
        {
            field: "entity",
            headerName: "Entity",
            flex: 1,
            sortable: false,
            renderCell: (params) => <EntitiesSelector initialValue={params.row.entity} onChange={(e, newValue)=> entityChanged(params.row, newValue)}/>,
        },
        {
            field: "relationship",
            headerName: "Relationship",
            flex: 1,
            sortable: false,
            renderCell: (cellParams) => {
                const defaultValue = relationships.find(r=>r.value == cellParams.row.relationship)
                return (<Box className="autocomplete-container" sx={{width: "100%", padding: 0}}>
                    <Autocomplete
                        popupIcon={<ArrowDownSymbols/>}
                        id={`relation_picker_${cellParams.row.id}`}
                        options={relationships}
                        defaultValue={defaultValue}
                        isOptionEqualToValue={(options, value) => options.value === value.value}
                        ListboxProps={{
                            style: {
                                maxHeight: "344px",
                            }
                        }}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                className="autocomplete-select"
                                placeholder="Choose"
                            />
                        }
                        renderOption={(props, option) => (
                            <li {...props} className="MuiAutocomplete-option MuiAutocomplete-option-custom">
                                {option.label}
                                <span className="material-symbols-outlined check-icon">check</span>
                            </li>
                        )}
                        onChange={(e, newValue)=> entityRelationChanged(cellParams.row, newValue)}
                    />
                    </Box>)
            },
        },
        {
            field: "deleteAction",
            headerName: "",
            width: 30,
            sortable: false,
            renderCell: (params) => (
                <span
                    className="material-symbols-outlined"
                    style={{cursor: "pointer"}}
                    onClick={() => handleDeleteClick(params.row)}
                >
                    delete
                </span>
            ),
        },
    ];

    return (
        <Box className="flex-box-col-center" sx={{maxWidth: "620px", width: "100%"}}>

            <Box className="dim-table-style" sx={{width: "100%", marginTop: "32px"}}>
                {entities.length > 0 && (
                    <DataGrid
                        hideFooter={true}
                        rows={getValues("entities")}
                        columns={columns}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        disableAutosize={false}
                        className="entity-data-grid"
                        sx={{
                            margin: "16px 0 0 0",
                        }}
                        slots={{toolbar: GridToolbar}}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: false,
                            },
                        }}
                        autoHeight={true}
                    />
                )}
                <ButtonComponent onClick={handleAddClick} variant="textPurple" label="+ Add new"
                                 sx={{fontSize: "14px", marginTop: "16px", paddingLeft: 0}}
                />
            </Box>
        </Box>
    );
};

export default AddEditBusinessEntitiesRelatedTableFirst;
