import React, {useEffect, useState} from "react";
import {Box, Container, Divider, Popover, Typography} from "@mui/material";
import theme from "theme";
import BusinessEntitiesListAliases from "./business-entity-aliases/business-entity-aliases.component";
import BusinessEntityTabs from "./business-entity-tabs/business-entity-tabs.component";
import BusinessEntitiesListBreadcrumbs
    from "./business-entity-breadcrumbs/business-entity-breadcrumbs.component";
import {EntityDetail, getEntity as getEntityApi} from "../../services/entities";
import {EntityDetailResponse} from "types";
import {useOutletContext, useParams} from "react-router-dom";
import {useGlobalContext} from "../../context/global-context";
import {InfoIcon} from "lucide-react";
import {handleRequestError} from "../../utils/axios";
import LoadingSpinner from "../shared/LoadingSpinner";
import {DataAssetColumn, getDataAssetById} from "services/data-assets";
import ButtonComponent from "../shared/button/button.component";
import {EditSymbols} from "../../assets/icons";
import BusinessEntityFeaturesModal from "./business-entity-features-modal/business-entities-features-modal.component";
import BusinessEntitiesListKeyAsset from "./business-entity-key-asset/business-entity-key-asset.component";

interface BusinessEntityComponentProps {
    onClickEdit: () => void;
    onDeleteClick: (entityId: string) => void;
}

const BusinessEntityComponent:React.FC = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const {entityId} = useParams<{ entityId: string }>();
    const {selectedGitBranch, isEditMode} = useGlobalContext();
    const [entity, setEntity] = useState<EntityDetailResponse | null>(null);
    const [keyTableFields, setKeyTableFields] = useState<DataAssetColumn[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFeaturesModalOpen, setIsFeaturesModalOpen] = useState<boolean>(false);
    const {onClickEdit, onDeleteClick} = useOutletContext<BusinessEntityComponentProps>();

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const isPopoverOpen = Boolean(anchorEl);

    const getEntity = async () => {
        setIsLoading(true);
        setErrorMessage(null);

        try {
            const data = await getEntityApi(entityId || "", isEditMode ? selectedGitBranch : null);
            if (data.keyTable) {
                const keyAsset = await getDataAssetById(data.keyTable, isEditMode ? selectedGitBranch : null)
                setKeyTableFields(keyAsset.columns);
            }
            setEntity(data);
        } catch (error: any) {
            const message = handleRequestError(error);
            if (message) setErrorMessage(message);
            setEntity(null);
        } finally {
            setIsLoading(false);
        }
    };

    const handleToggleAddFeatureModalOpen = async (isOpen: boolean, shouldRefresh?: boolean) => {
        setIsFeaturesModalOpen(isOpen);
        if (shouldRefresh)
            await getEntity()
    };

    useEffect(() => {
        getEntity();
    }, [entityId, isEditMode, selectedGitBranch]);

    useEffect(() => {
        if (entity) document.getElementsByTagName("title")[0].text = `${entity.name} - Lynk Studio`
    }, [entity]);


    return (
        <Container>
            <Box
                className="flex-box-start"
                flexDirection="column"
                sx={{height: "100%", margin: "32px 0 0 50px"}}
            >

                <BusinessEntitiesListBreadcrumbs entityName={entity?.name || entityId || ""}/>

                {errorMessage && !entity && (
                    <Box className="flex-box-row" margin="20px 0">
                        <InfoIcon/>
                        <Typography variant="subtitle2" paddingLeft="10px">
                            We couldn't load entity due to an error
                            {errorMessage}
                        </Typography>
                    </Box>
                )}

                {Boolean(entity) && (
                    <Box sx={{width: "90%"}}>
                        <Box margin="24px 0">
                            <Box className="flex-box-center-space-between">
                                <Typography variant="h2"
                                            sx={{
                                                fontSize: "42px",
                                                color: theme.palette.customColor.dark,
                                                letterSpacing: "-0.84px",
                                                lineHeight: "46px",
                                                fontWeight: 600,
                                                textTransform: "capitalize"
                                            }}>
                                    {entity?.name}
                                </Typography>
                                <Box className="flex-box-center-space-between">
                                    {isEditMode ? (
                                        <>
                                            <ButtonComponent onClick={onClickEdit} variant="contained" label="Edit" startIcon={<EditSymbols/>}/>

                                            <ButtonComponent onClick={handlePopoverOpen} variant="containedGrey" label="+ Add" sx={{ boxSizing: "border-box", backgroundColor: theme.palette.customColor.lightestGrey5, border: `3px solid ${theme.palette.customColor.lightGrey1}`, margin: "0 16px", padding: "9px 16px"}}/>

                                            <Popover
                                                open={isPopoverOpen}
                                                anchorEl={anchorEl}
                                                onClose={handlePopoverClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                                sx={{padding: "6px"}}
                                                PaperProps={{
                                                    sx: {
                                                        width: "222px",
                                                        borderRadius: "16px",
                                                        border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                                                        boxShadow: "0 4px 13px 0 rgba(97, 97, 97, 0.02)",
                                                        marginTop: "12px",
                                                        padding: 0,
                                                    },
                                                }}
                                            >
                                                <Box sx={{width: "100%"}}>
                                                    <ButtonComponent onClick={() => handleToggleAddFeatureModalOpen(true)} variant="text" label="Features" sx={{padding: "10px 16px"}}/>
                                                </Box>
                                            </Popover>

                                            <span className="material-symbols-outlined" style={{cursor: "pointer", fontSize: "18px"}}
                                                  onClick={() => onDeleteClick(entityId as string)}>delete</span>
                                        </>
                                    ) : null}

                                </Box>
                            </Box>

                            
                                <Box className="flex-box-start" sx={{marginTop: "24px"}}>
                                    <BusinessEntitiesListKeyAsset keyTableId={entity?.keyTable || ""}/>
                                    {entity?.aliases?.length ? (
                                        <>
                                            <Divider orientation="vertical" sx={{
                                                backgroundColor: theme.palette.customColor.lightGrey1,
                                                width: "1px",
                                                height: "58px",
                                                border: 0,
                                                margin: "0 40px"
                                            }}/>
                                            <Box>
                                                <BusinessEntitiesListAliases aliases={entity?.aliases}/>
                                            </Box>
                                        </>
                                    ) : <></>}
                                </Box>
                            

                        </Box>
                        <BusinessEntityTabs entity={entity} keyTableFields={keyTableFields} onDataRefresh={getEntity}/>
                    </Box>
                )}

                {(!entity && !isLoading && !errorMessage) && (
                    <Box className="flex-box-row" margin="20px 0">
                        <InfoIcon/>
                        <Typography variant="subtitle2" paddingLeft="10px">No entity information found</Typography>
                    </Box>
                )}
            </Box>

            {entity && (<BusinessEntityFeaturesModal entity={entity} isModalOpen={isFeaturesModalOpen}
                                                     onClose={(shouldRefresh) => handleToggleAddFeatureModalOpen(false, shouldRefresh)}/>)}

            <LoadingSpinner open={isLoading}/>
        </Container>
    );
};

export default BusinessEntityComponent;