import {Box, Typography} from "@mui/material";
import DataAssetRelationComponent
    from "components/add-edit-business-entity-component/add-edit-business-entities-related-datasets/business-entity-data-asset-relation.component";
import {
    EntityAssetRelation, RelatedDataAsset
} from "components/add-edit-business-entity-component/add-edit-business-entities-related-datasets/models";
import {default as EntityAssetRelationView}  from "components/shared/assets/entity-asset-relation/entity-asset-relation.component";
import ButtonComponent from "components/shared/button/button.component";
import React, {useEffect, useState} from "react";
import {getDataAssetById} from "services/data-assets";
import {RelationKind} from "services/entities";
import theme from "theme";

interface EntityToAssetRelationsProps {
    entityFields: string[];
    dataAssetId: string;
    relationKind?: RelationKind;
    sql: RelatedDataAsset["sql"];
    entityAssetRelations?: EntityAssetRelation[];
    setFieldsRelations: (relations: EntityAssetRelation[]) => void;
    setSqlRelations: (sql: RelatedDataAsset["sql"]) => void;
}

const EntityToAssetRelations: React.FC<EntityToAssetRelationsProps> = ({
                                                                           entityFields,
                                                                           dataAssetId,
                                                                           relationKind,
                                                                           sql,
                                                                           entityAssetRelations,
                                                                           setFieldsRelations,
                                                                           setSqlRelations
                                                                       }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [dataAssetFields, setDataAssetFields] = useState<string[]>([]);

    const fetchDataAsset = async (assetId: string) => {
        try {
            const asset = await getDataAssetById(assetId);
            setDataAssetFields(asset?.columns.map(c => c.name) || []);
        } catch (error: any) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        fetchDataAsset(dataAssetId);
    }, [dataAssetId]);

    const handleAddRelationClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const setFields = (newRelations: EntityAssetRelation[]) => {
        setFieldsRelations(newRelations);
        setAnchorEl(null);
    };

    const setSql = (sql: RelatedDataAsset["sql"]) => {
        setSqlRelations(sql);
        setAnchorEl(null);
    };

    if (!entityAssetRelations && !sql) {
        return (<Box display="flex">
            <ButtonComponent
                onClick={handleAddRelationClick}
                variant="textPurple"
                label="+ Add relation"
                sx={{fontSize: "14px", width: "100%", padding: 0}}
            />
            <DataAssetRelationComponent
                anchorEl={anchorEl}
                onSaveFields={setFields}
                onSaveSql={setSql}
                onClose={() => setAnchorEl(null)}
                relationKind={relationKind}
                sql={sql}
                entityFields={entityFields}
                dataAssetFields={dataAssetFields}
            />
        </Box>);
    }

    const relation: RelatedDataAsset = {
        id: 0,
        asset: null,
        assetFields: [],
        entityAssetRelations: entityAssetRelations,
        sql: sql,
        relationKind: entityAssetRelations && entityAssetRelations.length !== 0 ? RelationKind.fields : RelationKind.sql
    }

    return (
        <EntityAssetRelationView relation={relation} />
    )
};

export default EntityToAssetRelations;
