import React from "react";
import {Box, Modal} from "@mui/material";
import AddFeaturesComponent from "../../add-features-component/add-features.component";
import { EntityDetailResponse } from "services/entities";
import {FirstLastProvider} from "../../../context/first-last-feature-context";


interface BusinessEntityFeaturesModalProps {
    isModalOpen: boolean;
    onClose: (shouldRefresh?: boolean) => void;
    entity: EntityDetailResponse
}

const BusinessEntityFeaturesModal: React.FC<BusinessEntityFeaturesModalProps> = ({
                                                                                     isModalOpen,
                                                                                     onClose,
                                                                                 }) => {
    return (
        <FirstLastProvider>
            <Modal className="features-modal" open={isModalOpen} onClose={()=>onClose()}>
                <Box className="container-modal">
                    <AddFeaturesComponent handleClickFeatureClose={onClose}/>
                </Box>
            </Modal>
        </FirstLastProvider>
    );
};

export default BusinessEntityFeaturesModal;
