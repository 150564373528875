import React, {useEffect, useState} from "react";
import {Box, Container, Typography} from "@mui/material";
import theme from "theme";
import {useGlobalContext} from "context/global-context";
import {getEntities as getEntitiesApi, deleteEntity as deleteEntityApi} from "services/entities";
import {EntityResponse} from "types/index";
import LoadingSpinner from "components/shared/LoadingSpinner";
import {handleRequestError} from "../../utils/axios";
import {ArrowBack} from "../../assets/icons";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {containsText} from "../../utils/contains-text";
import BusinessEntitiesList from "./business-entities-list/business-entities-list.component";
import BusinessEntitiesModalViewComponent from "./business-entities-modal/business-entities-modal-view.component";

const BusinessEntitiesComponent: React.FC = () => {
    const {selectedGitBranch, isEditMode, drawerOpen} = useGlobalContext();
    const [entities, setEntities] = useState<EntityResponse[]>([]);
    const [editingEntityId, setEditingEntityId] = useState<string | null | undefined>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [openModal, setOpenModal] = useState(false);
    const [isLoading, toggleLoadingState] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>("");
    const {entityId} = useParams<{ entityId: string }>();
    const navigate = useNavigate();

    const openAddEditEntityModal = (entityId?: string) => {
        setEditingEntityId(entityId || null);
        setOpenModal(true);
    };

    const handleCloseModal = async (shouldRefresh: boolean) => {
        setOpenModal(false);
        if (shouldRefresh) await getEntities();
    };

    const deleteEntity = async (entityId: string) => {
        await deleteEntityApi(entityId, selectedGitBranch || "");
        await getEntities();
        navigate("/entities");
    };

    const getEntities = async () => {
        setErrorMessage(null);
        toggleLoadingState(true);

        try {
            const data = await getEntitiesApi(isEditMode ? selectedGitBranch : null);
            const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
            setEntities(sortedData);
        } catch (error: any) {
            const message = handleRequestError(error);
            if (message) setErrorMessage(error.message);
            setEntities([]);
        } finally {
            toggleLoadingState(false);
        }
    };

    const filteredEntities = entities.filter((entity) =>
        containsText(entity.name, searchValue)
    );

    useEffect(() => {
        getEntities();
    }, [selectedGitBranch, isEditMode]);

    return (
        <Container sx={{position: "relative", marginTop: 0, marginLeft: "50px"}}>
            <Box className="flex-box-start">
                <BusinessEntitiesList
                    entities={entities}
                    isEditMode={isEditMode}
                    openAddEditEntityModal={openAddEditEntityModal}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    filteredEntities={filteredEntities}
                    errorMessage={errorMessage}
                    isLoading={isLoading}
                    entityId={entityId}
                />

                <Box className="flex-box-col-center" sx={{
                    width: drawerOpen ? "calc(75% - 125px)" : "75%",
                    justifyContent: "flex-start",
                }}>
                    {entityId ? (
                        <Outlet context={{
                            onClickEdit: () => openAddEditEntityModal(entityId),
                            onDeleteClick: deleteEntity
                        }}/>
                    ) : (
                        <Box className="flex-box-col-center" sx={{margin: "20% 0"}}>
                            <ArrowBack/>
                            <Typography variant="h3" sx={{textAlign: "center", color: theme.palette.customColor.lightGrey2}}>
                                Select an entity <br/> on the left
                            </Typography>
                        </Box>
                    )}
                </Box>

                <Box className="flex-box-col-center" sx={{margin: "32px auto 0 auto"}}>
                    <BusinessEntitiesModalViewComponent
                        entityId={editingEntityId}
                        open={openModal}
                        onClose={handleCloseModal}
                    />
                </Box>

                <LoadingSpinner open={isLoading}/>
            </Box>
        </Container>
    );
};

export default BusinessEntitiesComponent;