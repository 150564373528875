import {Autocomplete, Box, Popover, TextField, Typography} from "@mui/material";
import {ArrowDownSymbols} from "assets/icons";
import {
    EntityAssetRelation,
    RelatedDataAsset
} from "components/add-edit-business-entity-component/add-edit-business-entities-related-datasets/models";
import ButtonComponent from "components/shared/button/button.component";
import CustomSwitch from "components/shared/switch/switch.component";
import React, {useEffect, useMemo, useState} from "react";
import {EntityRelatedAsset, RelationKind} from "services/entities";
import theme from "theme";

interface DataAssetRelationComponentProps {
    anchorEl: HTMLElement | null;
    dataAssetFields: string[];
    sql: RelatedDataAsset["sql"];
    entityFields?: string[];
    relationKind?: RelationKind;
    onSaveFields: (newRelations: EntityAssetRelation[]) => void;
    onSaveSql: (sql: EntityRelatedAsset["sql"]) => void;
    onClose: () => void;
}

const DataAssetRelationComponent: React.FC<DataAssetRelationComponentProps> = ({
                                                                                   anchorEl,
                                                                                   dataAssetFields,
                                                                                   sql,
                                                                                   entityFields = [],
                                                                                   relationKind = RelationKind.fields,
                                                                                   onSaveFields,
                                                                                   onSaveSql,
                                                                                   onClose,
                                                                               }) => {
    const defaultRelation = {
        entityField: "", dataAssetField: ""
    };
    const [selectedRelationKind, setSelectedRelationKind] = useState<EntityRelatedAsset["type"]>(relationKind);
    const [selectedSQL, setSelectedSQL] = useState<EntityRelatedAsset["sql"]>("");
    const [selectedRelations, setSelectedRelations] = useState<EntityAssetRelation[]>([defaultRelation]);
    const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false);
    const handleRelationChange = (index: number, key: "entityField" | "dataAssetField", newValue: string) => {
        const newRelations = [...selectedRelations];
        newRelations[index][key] = newValue as string;
        setSelectedRelations(newRelations);
    };

    const handleAddCondition = () => {
        setSelectedRelations([...selectedRelations, defaultRelation]);
    };

    const handleRemoveCondition = (index: number) => {
        const newRelations = selectedRelations.filter((_, i) => i !== index);
        setSelectedRelations(newRelations);
    };

    const getValidRelations = () =>
        selectedRelations.filter(r => r.entityField.length && r.dataAssetField.length);

    const saveRelations = () => {
        if (selectedRelationKind === RelationKind.fields) {
            onSaveFields(getValidRelations());
        }
        if (selectedRelationKind === RelationKind.sql) {
            onSaveSql(selectedSQL);
        }
    };

    useEffect(() => {
        if (selectedRelationKind === RelationKind.fields) {
            setIsSaveEnabled(getValidRelations().length > 0);
        }
        if (selectedRelationKind === RelationKind.sql) {
            setIsSaveEnabled(selectedSQL.length > 0); // we should add SQL validation
        }
    }, [selectedRelations, selectedSQL, selectedRelationKind]);

    return (
        <Popover
            open={anchorEl !== null}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            slotProps={{
                paper: {
                    sx: {
                        padding: "16px",
                        border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                        borderRadius: "16px",
                        boxShadow: "0px 4px 13px 0px rgba(97, 97, 97, 0.02)",
                        width: "500px",
                    }
                }
            }}
        >
            <Box sx={{mb: 2}}>
                <Typography sx={{fontSize: "14px", color: "text.secondary"}}>
                    Define the relationship between &lt;entity&gt; and &lt;data asset name&gt;
                </Typography>
            </Box>
            {selectedRelationKind === RelationKind.fields ? (
                    <>
                        <Box className="flex-box-row" sx={{mb: 2}}>
                            <Typography variant="subtitle2" sx={{display: "flex", width: "50%"}}>
                        <span
                            className="material-symbols-outlined"
                            style={{
                                fontSize: "24px",
                                color: theme.palette.customColor.purple,
                                marginRight: "6px",
                            }}
                        >
                            package_2
                        </span>
                                Entity
                            </Typography>
                            <Typography variant="subtitle2" sx={{display: "flex", width: "50%"}}>
                        <span
                            className="material-symbols-outlined"
                            style={{
                                fontSize: "24px",
                                color: theme.palette.customColor.purple,
                                marginRight: "6px",
                            }}
                        >
                            database
                        </span>
                                Data asset name
                            </Typography>
                        </Box>
                        {selectedRelations.map((relation, index) => (
                            <Box key={index} sx={{display: "flex", alignItems: "center", mb: 1, fontSize: "16px"}}>
                                <Autocomplete
                                    {...relation.entityField && {value: relation.entityField}}
                                    popupIcon={<ArrowDownSymbols/>}
                                    onChange={(e, newValue) => handleRelationChange(index, "entityField", newValue || "")}
                                    options={entityFields}
                                    autoHighlight
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            className="autocomplete-select"
                                            placeholder="Choose entity field"/>
                                    } ListboxProps={{
                                    sx: {
                                        maxHeight: "344px",
                                    }
                                }}
                                    fullWidth
                                />
                                <Box margin={"0 10px"}>=</Box>
                                <Autocomplete
                                    {...relation.dataAssetField && {value: relation.dataAssetField}}
                                    popupIcon={<ArrowDownSymbols/>}
                                    onChange={(event, newValue) =>
                                        handleRelationChange(index, "dataAssetField", newValue || "")}
                                    options={dataAssetFields}
                                    autoHighlight
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            className="autocomplete-select"
                                            placeholder="Choose asset field"/>
                                    }
                                    ListboxProps={{
                                        sx: {
                                            maxHeight: "344px",
                                        }
                                    }}
                                    sx={{marginRight: "10px"}}
                                    fullWidth
                                />
                                <span role="button"
                                      className="material-symbols-outlined"
                                      color={theme.palette.customColor.grey}
                                      onClick={() => handleRemoveCondition(index)}>
                        delete
                    </span>
                            </Box>
                        ))}
                        <ButtonComponent
                            variant="textPurple"
                            sx={{color: "primary.main", textTransform: "none", marginLeft: "-15px"}}
                            onClick={handleAddCondition}
                            label="+ Add condition"/>
                    </>
                ) :
                <TextField
                    sx={{mb: 2}}
                    className="measures-input"
                    placeholder="Put your code from SQL..."
                    minRows={3}
                    multiline
                    value={selectedSQL}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedSQL(event.target.value)}
                />
            }
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <Box sx={{display: "flex", alignItems: "center"}}>
                    <CustomSwitch checked={selectedRelationKind === RelationKind.sql}
                                  onChange={(event) =>
                                      setSelectedRelationKind(event.target.checked ? RelationKind.sql : RelationKind.fields)}/>
                    <Typography variant="subtitle2" sx={{ml: 1}}>SQL</Typography>
                </Box>
                <Box sx={{display: "flex"}}>
                    <ButtonComponent
                        variant="text"
                        label="Cancel"
                        onClick={onClose}
                        sx={{color: "text.secondary", mr: 2, textTransform: "none"}}
                    />
                    <ButtonComponent
                        variant="contained"
                        sx={{
                            color: "white",
                            backgroundColor: "primary.main",
                            borderRadius: "8px",
                            textTransform: "none",
                            "&:hover": {
                                backgroundColor: "primary.dark",
                            },
                        }}
                        onClick={saveRelations}
                        disabled={!isSaveEnabled}
                        label="Apply"
                    />
                </Box>
            </Box>
        </Popover>
    );
};

export default DataAssetRelationComponent;