import React from "react";
import {useForm, Controller} from "react-hook-form";
import {Box, TextField, FormControl, Typography} from "@mui/material";
import theme from "theme";
import ButtonComponent from "components/shared/button/button.component";
import { MeasureType } from "types/features";

interface FormField {
    id: number;
    label: string;
    placeholder: string;
    required: boolean;
    name: string;
    render?: (formField: FormField, field: any) => JSX.Element
}

interface AddFeaturesMeasuresStepCreateProps {
    onClose: () => void;
    onNewMeasureCreated: (measure: MeasureType) => void;
}

const AddFeaturesMeasuresStepCreate: React.FC<AddFeaturesMeasuresStepCreateProps> = ({
                                                                                         onClose,
                                                                                         onNewMeasureCreated
                                                                                     }) => {
    const {
        control,
        handleSubmit,
        formState: {errors, isValid},
    } = useForm({mode: "onChange"});

    const formFields: FormField[] = [
        {
            id: 1,
            label: "Name",
            placeholder: "Measure Name...",
            required: true,
            name: "name",
        },
        {
            id: 2,
            label: "SQL Definition",
            placeholder: "e.g. - sum(total_sales)",
            required: true,
            name: "definition",
            render: (formField: FormField, field) => (
                <TextField
                    className="measures-input"
                    {...field}
                    placeholder={formField.placeholder}
                    multiline
                    minRows={2}
                    error={!!errors[formField.name]}
                    helperText={
                        errors[formField.name]
                            ? `${formField.label} is required`
                            : ""
                    }
                />
            )
        },
        {
            id: 3,
            label: "Description",
            placeholder: "Measure description...",
            required: false,
            name: "description",
            render: (formField: FormField, field) => (
                <TextField
                    className="measures-input"
                    {...field}
                    placeholder={formField.placeholder}
                    multiline
                    minRows={4}
                    error={!!errors[formField.name]}
                    helperText={
                        errors[formField.name]
                            ? `${formField.label} is required`
                            : ""
                    }
                />
            )
        },
    ];

    const onSubmit = (data: any) => {
        onNewMeasureCreated({
            name: data.name,
            definition: data.definition,
            description: data.description,
            selected: false,
            isNew: true
        });
        onClose();
    };

    return (
        <Box className="flex-box-col-start">
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                className="flex-box-col-start"
                sx={{width: "100%"}}
            >
                {formFields.map((formField) => (
                    <FormControl key={formField.id} sx={{width: "100%", marginBottom: "24px"}}>
                        <Typography
                            variant="h5"
                            lineHeight="22px"
                            mb="10px"
                            sx={{color: theme.palette.customColor.darkGrey}}
                        >
                            {formField.label}
                        </Typography>
                        <Controller
                            name={formField.name}
                            control={control}
                            rules={{required: formField.required}}
                            render={({field}) => {
                                return formField.render ? 
                                    formField.render(formField, field):
                                    (<TextField
                                        className="measures-input"
                                        {...field}
                                        placeholder={formField.placeholder}
                                        error={!!errors[formField.name]}
                                        helperText={
                                            errors[formField.name]
                                                ? `${formField.label} is required`
                                                : ""
                                        }
                                    />);
                            }}
                        />
                    </FormControl>
                ))}
                <Box className="flex-box-end" sx={{marginTop: "8px"}}>
                    <ButtonComponent
                        type="button"
                        variant="text"
                        label="Cancel"
                        onClick={onClose}
                        sx={{fontSize: "14px", lineHeight: "22px"}}
                    />
                    <ButtonComponent
                        type="submit"
                        disabled={!isValid}
                        variant="contained"
                        label="Create"
                        onClick={() => {
                        }}
                        sx={{fontSize: "14px", lineHeight: "22px"}}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default AddFeaturesMeasuresStepCreate;
