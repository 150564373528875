import React, { useEffect, useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {Box, debounce} from '@mui/material';
import {ArrowDownSymbols} from "assets/icons";
import { DataAssetResponse, getDataAssets } from 'services/data-assets';
import { RefCallBack } from 'react-hook-form';

interface Props {
    onChange: (event: any, newValue: DataAssetResponse | null) => void;
    initialValue?: DataAssetResponse | null
    name?: string,
    inputRef?: RefCallBack | undefined,
}

const DataAssetSelector: React.FC<Props> = ({onChange, initialValue, inputRef}) => {
    const [inputValue, setInputValue] = React.useState("");
    const [options, setOptions] = useState<readonly DataAssetResponse[]>([]);
    
    const fetchAssets = useMemo(
        () =>
          debounce(async (filter?: string) => {
                const dataAssetsPage = await getDataAssets({
                    tableId: filter
                })
                setOptions(dataAssetsPage.assets)
            },
            400,
          ),
        [],
      );
    
    const onAutocompleteChanged = (event: any, newValue: DataAssetResponse | null) => {
        onChange(event, newValue)
    }

    useEffect(()=>{ 
        fetchAssets(inputValue)
    },[inputValue, fetchAssets])

    return (
        <Box className="autocomplete-container" sx={{width: 300, padding: 0}}>
            <Autocomplete
                ref={inputRef}
                popupIcon={<ArrowDownSymbols/>}
                onChange={onAutocompleteChanged}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                autoHighlight
                options={options}
                defaultValue={initialValue}
                getOptionLabel={(option) => option.id}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                ListboxProps={{
                    sx: {
                        maxHeight: '344px',
                    }
                }}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        className="autocomplete-select"
                        placeholder="Choose table"
                    />
                }
            />
        </Box>
    );
};

export default DataAssetSelector;
